import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCa-kh60iFTLgfHUrokr6RBe9hmL7NeeXg",
  authDomain: "mypop-demo-f4a19.firebaseapp.com",
  projectId: "mypop-demo-f4a19",
  storageBucket: "mypop-demo-f4a19.appspot.com",
  messagingSenderId: "475443597405",
  appId: "1:475443597405:web:091917e36e6a68d1323db4",
  measurementId: "G-DJF5TWHZVY"
};

const app = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const storageRef = firebase.storage().ref();
const auth = firebase.auth();

export {
  app, db, storageRef, auth,
};
