import React from 'react';
import { useSelector } from 'react-redux';
import {
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { app } from '../../../firebase';
import Scroll from'react-scroll';
import { ConsumerHomeContext, ConsumerHomeContainer, CustomButton, HorizontalLineSmall } from '../../index.styled';
import phone from '../../../images/Screen2newmen2.png';
import { Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Registration from '../../Authentication/Registration';
import Login from '../../Authentication/Login';
import appStore from '../../../images/AppStore.png';
import playStore from '../../../images/PlayStore.png';

const Element  = Scroll.Element;

const useStyles = makeStyles({
  typographyBold: {
    fontFamily: 'Gotham-Rounded-Medium !important'
  },
  typography: {
    fontFamily: 'Gotham-Rounded-Book !important'
  },
  typographyUnderlined: {
    fontFamily: 'Gotham-Rounded-Light !important',
    textDecoration: 'underline',
  },
  homeHeadline: {
    marginTop: '150px'
  },
  homeHeadlineMobile: {
    marginTop: '20px',
    marginLeft: '30px'
  }
});

export const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  const currentUser = useSelector((state) => state.users.currentUser);

  const signOut = () => {
    app.auth().signOut();
  };

  return (
    <Element name="consumer-home">
      <ConsumerHomeContainer isMobile={isMobile}>
        <div style={{maxWidth: '850px', marginLeft: 'auto', marginRight: 'auto', display: 'flex' }}>
          <ConsumerHomeContext isMobile={isMobile}>
            <img src={phone} style={{ height: !isMobile ? '470px' : '350px', marginTop: '120px' }} alt="phone"/>
            <div className={isMobile ? classes.homeHeadlineMobile : classes.homeHeadline}>
              <HorizontalLineSmall />
              <br />
              <Typography variant='h4' className={classes.typographyBold}>
                Welcome money saving offers.
              </Typography>
              <Typography variant='h4' className={classes.typographyBold}>
                Goodbye paper.
              </Typography>
              <Typography variant='h4' className={classes.typographyBold}>
                Hello POP
              </Typography>
              <br />
              <HorizontalLineSmall />
              <br />
              <Typography variant='body1' className={classes.typography}>
                My-POP is the only loyalty offers and digital receipting app you'll ever need. It's quick, easy, and, oh yes, you can save the planet.
              </Typography>
              <br />
              <div style={{
                display: 'flex',
              }}>
              {
                (currentUser?.uid) ? (
                  <>
                    <CustomButton onClick={() => signOut()}>
                      Log out
                    </CustomButton>
                    <CustomButton onClick={() => window.location.href='/consumer-transactions'} style={{ marginLeft: '20px' }}>
                      Dashboard
                    </CustomButton>
                  </>
                ) : (
                  <>
                    <Login smallButton={isMobile} />
                    <Registration smallButton={isMobile} />
                  </>
                )
              }
              </div>
            </div>
          </ConsumerHomeContext>
        </div>
      </ConsumerHomeContainer>
        <div style={{ background: 'yellow', height: 'auto', textAlign: 'center' }}>
        <br />
        <br />
        <div>
          <Typography variant='h4' className={classes.typographyBold}>
            Available on both IOS and Android. Download for FREE now!
          </Typography>
        </div>
        <br />
        <div style={{ display: 'flex', width: '60%', marginLeft: 'auto', marginRight: 'auto', justifyContent: 'center' }}>
          <a href='https://apps.apple.com/in/app/mypop/id943510047' rel="noreferrer"  target='_blank'><img className={classes.store} src={appStore} alt='appstore' /></a>
          <a href='https://play.google.com/store/apps/details?id=com.nvbr22gmailcom.mypopapp' rel="noreferrer"  target='_blank'><img className={classes.store} src={playStore} alt='playstore' /></a>
        </div>
        <br />
      </div>
    </Element>
  )
}

export default Home;
