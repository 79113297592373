import React, { useEffect, useState } from 'react';
import { ConsumerTransactionsContainer, CustomGreenButton, CustomInput, CustomSelect } from '../index.styled';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Redirect } from 'react-router-dom';
import { getProducts } from '../../services/productService';
import { getUserByMyPopId } from '../../services/userService';
import { getStoresByRetailerId } from '../../services/storeService';
import { Add, Remove } from '@material-ui/icons';
import ReviewTransaction from './ReviewTransaction';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#B5B5B5',
    color: theme.palette.common.white,
    fontFamily: 'Gotham-Rounded-Medium !important',
    textTransform: 'uppercase'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#3B3D40',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const useStyles = makeStyles((theme) => ({
  typographyBold: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#426A6D',
    textDecoration: 'none'
  },
  typographyLight: {
    fontFamily: 'Gotham-Rounded-Book !important',
    color: '#426A6D',
    textDecoration: 'none'
  }
}));

const Demo = () => {
  const classes = useStyles();
  const [myPopId, setMyPopId] = useState('');
  const [customer, setCustomer] = useState({});
  const [store, setStore] = useState({});
  const [stores, setStores] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [vat, setVat] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    setFilteredProducts(products.filter(p => p.name.toLowerCase().includes(e.target.value.toLowerCase())));
  }

  const currentUser = useSelector((state) => state.users.currentUser);

  const handleMyPopIdChange = (e) => {
    setMyPopId(e.target.value);
  };

  const handleStoreChange = (e) => {
    setStore(e.target.value);
  };

  const handleGetCustomerClick = () => {
    getUserByMyPopId(myPopId, currentUser.token).then((response) => {
      setCustomer(response.data);
    }).then(() => {
      setMyPopId('');
    })
  };

  const addProductToCart = (product) => {
    const productExists = selectedProducts.find(item => item.id === product.id);
    if (productExists) {
      setSelectedProducts(
        selectedProducts.map(item => {
          if (item.id === product.id) {
            return {...item, quantity: productExists.quantity + 1};
          }

          return item;
        })
      )
    } else {
      const arr = selectedProducts;
      arr.push(
        {
          ...product,
          quantity: 1
        }
      );
      setSelectedProducts(arr);
    };
    let t = total;
    t += product.price;
    setTotal(t)

    let v = vat;
    v += product.vat;
    setVat(v);
  };

  const removeProductFromCart = (id) => {
    const arr = selectedProducts;
    const index = arr.findIndex(obj => {
      return obj.id === id;
    });
    arr.splice(index, 1);
    setSelectedProducts(arr);
  }

  useEffect(() => {
    getStoresByRetailerId(currentUser.retailer_id, currentUser.token).then((response) => {
      setStores(response.data);
    });

    getProducts(currentUser.token).then((response) => {
      setProducts(response.data);
      setFilteredProducts(response.data);
    });
  }, [currentUser.retailer_id, currentUser.token]);

  return (
    <>
      {
        (currentUser.retailer_id) ? (
          <>
            <ConsumerTransactionsContainer>
              <Typography variant='h5' className={classes.typographyBold}>
                Hi {currentUser.firstName},
              </Typography>
              <Typography variant='h5' className={classes.typographyBold}>
                Welcome to My-POP demo page. Here you can simulate a transaction.
              </Typography>
              <br />
              <br />
              <Typography className={classes.typographyLight}>
                Scan customer's QR code and paste My-POP ID
              </Typography>
              <CustomInput
                placeholder='Enter customer My-POP...'
                value={myPopId}
                onChange={handleMyPopIdChange}
                disabled={customer.myPopId}
              />
              <CustomGreenButton
                onClick={() => handleGetCustomerClick()}
                disabled={customer.myPopId || myPopId === ""}
              >
                Ok
              </CustomGreenButton>
              <br />
              <br />
              {
                (customer.myPopId) ? (
                  <>
                    <Typography className={classes.typographyBold}>
                      Customer: {customer.firstName} {customer.lastName}
                    </Typography>
                    <br />
                    <Typography className={classes.typographyLight}>
                      Choose store:
                    </Typography>
                    <CustomSelect
                      placeholder='Store*'
                      aria-label='Store'
                      value={store}
                      onChange={handleStoreChange}
                    >
                      <option value="" disabled defaultValue="">Select store *</option>
                      {
                        stores.map((store) => {
                          return <option key={store.s_id} value={store.s_id}>{store.s_name}</option>
                        })
                      }
                    </CustomSelect>
                    <br />
                    <Typography className={classes.typographyLight}>
                      Choose products:
                    </Typography>
                    <div>
                      <CustomInput style={{
                        border: '1px solid #D5D5D5'
                      }} value={searchTerm} onChange={handleSearchTermChange} placeholder='Search for products...' />
                    </div>
                    <div className={classes.tableContainer}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="right">Name</StyledTableCell>
                              <StyledTableCell align="right">Description</StyledTableCell>
                              <StyledTableCell align="right">Category</StyledTableCell>
                              <StyledTableCell align="right">Price</StyledTableCell>
                              <StyledTableCell align="right">VAT</StyledTableCell>
                              <StyledTableCell align="right">Actions</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                          {filteredProducts.map((row) => {
                            return (
                            <StyledTableRow key={row.uid}>
                              <StyledTableCell component="th" scope="row">
                                {row.name}
                              </StyledTableCell>
                              <StyledTableCell align="right">{row.description}</StyledTableCell>
                              <StyledTableCell align="right">{row.category}</StyledTableCell>
                              <StyledTableCell align="right">{row.price}</StyledTableCell>
                              <StyledTableCell align="right">{row.vat}</StyledTableCell>
                              <StyledTableCell align="right">
                                <CustomGreenButton
                                  style={{ width: '120px' }}
                                  onClick={() => addProductToCart(row)}
                                >
                                  Add
                                  <Add />
                                </CustomGreenButton>
                                <br />
                                <br />
                                <CustomGreenButton
                                  style={{ width: '120px' }}
                                  onClick={() => removeProductFromCart(row.id)}
                                >
                                  Remove
                                <Remove />
                                </CustomGreenButton>
                              </StyledTableCell>
                              
                            </StyledTableRow>
                            )
                          })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <br />
                    <Typography className={classes.typographyBold}>
                      Cart:
                    </Typography>
                    {selectedProducts.map((p) => {
                      return (
                        <Typography className={classes.typographyBold}>
                          {p.name} x {p.quantity}
                        </Typography>
                      )
                    })}
                    <br />
                    <ReviewTransaction selectedProducts={selectedProducts} total={total} vat={vat} store={store} customer={customer} />
                  </>
                ) : (
                  <span />
                )
              }
            </ConsumerTransactionsContainer>
          </>
        ) : (
          <Redirect to='/' />
        )
      }
    </>
  )
}

export default Demo;
